<template>
  <div id="landingpage">
    <Header />
    <c-box :paddingTop="['0', '30px']" :paddingBottom="['0', '80px']">
      <Banner />
    </c-box>

    <c-box
      id="program"
      :px="['20px', '0']"
      py="80px"
      backgroundColor="rgba(0, 140, 129, 0.1)"
    >
      <c-box textAlign="center" marginBottom="80px">
        <CHeading
          as="h2"
          color="brand.900"
          textTransform="uppercase"
          :fontSize="['24px', '48px']"
        >
          PROGRAM DIETELA
        </CHeading>
      </c-box>
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        justify="center"
        align="center"
      >
        <c-flex
          v-for="(item, index) in products"
          :key="index"
          w="100%"
          maxWidth="896px"
          :pr="['0', '78px']"
          :py="['0', '44px']"
          backgroundColor="white"
          borderRadius="12px"
          :overflow="['hidden', 'unset']"
          boxShadow=" 0px 1px 24px 4px rgba(206,224,224,1)"
          marginBottom="50px"
          :flexDirection="['column', 'row']"
          :paddingLeft="index % 2 == 1 ? [null, '78px'] : ''"
          :paddingRight="index % 2 == 1 ? [null, '0'] : ''"
        >
          <c-box
            :min-w="['100%', '286px']"
            :maxWidth="['100%', '286px']"
            overflow="hidden"
            pos="relative"
            :left="index % 2 == 1 ? 'auto' : ['0', '-70px']"
            :right="index % 2 == 1 ? [null, '-70px'] : ''"
            :order="index % 2 == 1 ? [null, 1] : ''"
          >
            <c-image
              :borderRadius="['0', '12px']"
              :src="getPhotoProgram(item.photoUrl)"
              alt="dietela"
              w="100%"
              :maxWidth="['auto', '100%']"
            />
          </c-box>
          <c-box
            textAlign="left"
            :paddingTop="['20px', '10px']"
            :paddingBottom="['20px', '60px']"
            :px="['20px', '0']"
            pos="relative"
          >
            <CHeading
              as="h3"
              :color="['white', 'brand.900']"
              :fontSize="['24px', '36px']"
              fontWeight="700"
              :marginBottom="['45px', '25px']"
              :marginleft="['20px', '0']"
              :marginTop="['-80px', '0']"
            >
              {{ item.name ? item.name : 'Data "NAME" Masih Kosong' }}
            </CHeading>
            <c-text
              fontSize="18px"
              lineHeight="36px"
              fontFamily="Roboto"
              color="darkGray.900"
              :min-w="[null, '530px']"
            >
              {{ item.description }}
            </c-text>
            <c-button
              right-icon="arrow-forward"
              :backgroundColor="['transparent', 'secondary.900']"
              :color="['brand.900', '#927102']"
              borderRadius="100px"
              fontSize="16px"
              lineHeight="24px"
              borderWidth="1px"
              boderStyle="solid"
              borderColor="transparent"
              :px="['0', '28px']"
              py="24px"
              :pos="['initial', 'absolute']"
              left="0"
              bottom="0"
              fontFamily="Roboto"
              :isDisabled="isDetailProduct"
              @click="onDetailProduct(item.id)"
              v-chakra="{
                ':hover': {
                  backgroundColor: 'white',
                  color: 'brand.900',
                  borderWidth: '1px',
                  boderStyle: 'solid',
                  borderColor: 'brand.900',
                },
              }"
            >
              Selengkapnya
            </c-button>
            <DetailProducts
              v-if="isOpen"
              :item="product"
              :product-id="productId"
              :product-photo="item.photoUrl"
              :is-open="isOpen"
            />
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
    <Footer />

    <NavBottom />

    <c-box
      v-if="isHavingPendingQuisionary"
      pos="fixed"
      :bottom="['60px', '20px']"
      :right="[null, '20px']"
    >
      <c-alert status="warning">
        <c-alert-icon />
        <c-alert-description>
          Kamu punya kuesioner yang belum selesai, mohon untuk melengkapinya
          <c-button
            size="sm"
            variant="ghost"
            variant-color="brand.900"
            as="router-link"
            :to="{
              name: 'client.kuisioner',
              params: { programId: latestProgramId },
            }"
          >
            Lengkapi
          </c-button>
        </c-alert-description>
      </c-alert>
    </c-box>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue"
import Header from "@/components/header/index.vue"
import Banner from "@/components/Banner.vue"
import NavBottom from "@/components/nav-bottom.vue"

import { mapActions, mapGetters, mapState } from "vuex"
import DetailProducts from "@/views/client/detail-products.vue"
import generalMixin from "@/utils/general-mixins"

export default {
  name: "LandingPage",
  mixins: [generalMixin],
  components: {
    NavBottom,
    Header,
    Footer,
    Banner,
    DetailProducts,
  },
  data() {
    return {
      isHavingPendingQuisionary: false,
      latestProgramId: null,
      isDetailProduct: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.userRole === "nutritionist") {
        vm.$router.replace({ name: "nutrisionist.managementClient" })
      } else {
        return true
      }
    })
  },
  computed: {
    ...mapGetters({
      products: "clients/products",
      product: "clients/product",
      isOpen: "clients/isOpen",
      isAuthenticated: "auth/isAuthenticated",
    }),
    ...mapState({
      userRole: (s) => s.auth?.user?.role,
    }),
    productId() {
      if (this.product) {
        return this.product.id
      }
      return null
    },
  },
  async mounted() {
    if (this.userRole === "super_admin") {
      return this.$router.push({ name: "superadmin" })
    }
    if (this.userRole === "admin") {
      return this.$router.push({ name: "admin" })
    }
    this.listProducts()
    this.onOpenModal(false)

    if (this.isAuthenticated && this.userRole === "client") {
      let programs = await this.listRiwayatPrograms()
      let latestProgram = programs?.find((it) => it.isLatest)

      if (latestProgram != null && latestProgram.nutritionistId != null) {
        let kuisioner = await this.getKuisioner(latestProgram.id)
        console.log("-> Kuisioner", kuisioner)

        let isCompleted = kuisioner.completedAt != null;
        let values = Object.values(kuisioner)

        if (!isCompleted || (values.length === 0 || values.some((it) => it == null))) {
          this.isHavingPendingQuisionary = true
          this.latestProgramId = latestProgram.id
        }
      }
    }
  },
  methods: {
    ...mapActions({
      listProducts: "clients/getProducts",
      detailProduct: "clients/getProductById",
      onOpenModal: "clients/onToggleModal",
      listRiwayatPrograms: "profileProgram/listHistoryPrograms",
      getKuisioner: "clients/getKuisioner",
    }),
    onDetailProduct(id) {
      this.isDetailProduct = true
      this.detailProduct(id).then(() => {
        this.onOpenModal(true)
        this.isDetailProduct = false
      })
    },
  },
}
</script>
<style>
body {
  overflow: inherit;
}
</style>
