import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1bc9d670&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CHeading: require('@chakra-ui/vue').CHeading, CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CAlertDescription: require('@chakra-ui/vue').CAlertDescription, CAlert: require('@chakra-ui/vue').CAlert})
