<template>
  <div>
    <VueSlickCarousel
      v-if="banners.length > 0"
      v-bind="settings"
      v-chakra="{
        '.slick-prev': {
          width: '40px',
          height: '40px',
          left: '50px',
          backgroundImage: 'url(/images/chevron-left.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          zIndex: '1',
          '::before': {
            display: 'none',
          },
          ':focus': {
            backgroundImage: 'url(/images/chevron-left.png)',
          },
          ':hover': {
            backgroundImage: 'url(/images/chevron-left.png)',
          },
          '@media(max-width: 767px)': {
            display: 'none !important',
          },
        },
        '.slick-next': {
          right: '50px',
          width: '40px',
          height: '40px',
          backgroundImage: 'url(/images/chevron-right.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          zIndex: '1',
          '::before': {
            display: 'none',
          },
          ':focus': {
            backgroundImage: 'url(/images/chevron-right.png)',
          },
          ':hover': {
            backgroundImage: 'url(/images/chevron-right.png)',
          },
          '@media(max-width: 767px)': {
            display: 'none !important',
          },
        },
        '.slick-dots': {
          li: {
            button: {
              '::before': {
                fontSize: '12px',
              },
            },
            '&.slick-active': {
              button: {
                '::before': {
                  color: 'brand.900',
                },
              },
            },
          },
        },
        '.slick-slide': {
          px: '30px',
          '@media(max-width: 767px)': {
            px: '0',
          },
        },
      }"
    >
      <c-box
        v-for="(item, index) in banners"
        :key="index"
        pos="relative"
        class="item"
        border-radius="6px"
      >
        <c-box
          w="100%"
          maxWidth="578px"
          pos="absolute"
          left="50%"
          :bottom="['40px', '80px']"
          transform="translateX(-50%)"
          :textAlign="['left', 'center']"
          zIndex="1"
        >
          <c-heading
            w="100%"
            :px="['20px', '']"
            as="h1"
            color="white"
            fontSize="18px"
            fontWeight="bold"
            lineHeight="27px"
            marginBottom="20px"
          >
            {{ item.title }}
          </c-heading>
          <c-text
            :display="['none', 'inline-block']"
            color="white"
            fontSize="14px"
            lineHeight="21px"
            marginBottom="33px"
            fontFamily="Roboto"
            v-html="item.content"
          >
          </c-text>
          <c-button
            v-if="item.url"
            as="a"
            right-icon="arrow-forward"
            backgroundColor="secondary.900"
            color="#927102"
            borderRadius="100px"
            fontSize="16px"
            borderWidth="1px"
            boderStyle="solid"
            borderColor="transparent"
            py="13px"
            px="28px"
            h="auto"
            lineHeight="24px"
            fontFamily="Roboto"
            :display="['none', 'inline-block']"
            v-chakra="{
              ':hover': {
                backgroundColor: 'white',
                color: 'brand.900',
                borderWidth: '1px',
                boderStyle: 'solid',
                borderColor: 'brand.900',
              },
            }"
            :href="item.url"
            target="_blank"
          >
            Coba Sekarang
          </c-button>
        </c-box>
        <c-box
          content=""
          width="100%"
          height="100%"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0) -18.82%, rgba(0, 0, 0, 0.6) 68.95%)"
          position="absolute"
          border-radius="6px"
          top="0"
          left="0"
        ></c-box>
        <c-image
          :src="item.picture.url"
          alt="dietela"
          w="100%"
          minHeight="380px"
        />
      </c-box>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters } from "vuex";

import { CBox, CImage, CHeading, CText, CButton } from "@chakra-ui/vue";

export default {
  name: "Banner",
  components: {
    VueSlickCarousel,
    CBox,
    CImage,
    CHeading,
    CText,
    CButton,
  },
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 1,
        infinite: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 5000,
      },
      banners: [],
    };
  },
  computed: {
    ...mapGetters({
      axiosCMS: "customAxiosCMS",
    }),
  },
  async mounted() {
    this.loadBanners();
  },
  methods: {
    loadBanners() {
      this.axiosCMS.get("/banners").then((res) => {
        this.banners = res.data;
      });
    },
  },
};
</script>