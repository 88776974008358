<template>
  <c-modal :is-open="isOpen">
    <c-modal-content
      ref="content"
      w="100%"
      maxWidth="1261px"
      :borderRadius="[null, '12px']"
      :my="[0, 'inherit']"
      :pt="['77px', null]"
      :h="['100%', 'auto']"
      :overflowY="['auto', 'inherit']"
    >
      <c-box
        :display="[null, 'none']"
        position="fixed"
        w="100%"
        top="0"
        z-index="1"
      >
        <Header :logo="'hide'" :back="true" :back-title="item.name" />
      </c-box>
      <c-modal-close-button
        :display="['none', 'block']"
        @click="onCloseModal(false)"
      />
      <c-modal-body px="0" paddingBottom="56px">
        <c-flex
          minHeight="380px"
          :mb="['0', '6px']"
          display="flex"
          alignItems="start"
          flexWrap="wrap"
          :paddingTop="['20px', '57px']"
          :paddingRight="['0', '57px']"
          :paddingBottom="['20px', '57px']"
          :paddingLeft="['0', '310px']"
        >
          <c-box
            w="231px"
            h="260px"
            borderRadius="12px"
            overflow="hidden"
            :pos="['initial', 'absolute']"
            :mx="['auto', '0']"
            left="57px"
            top="57px"
          >
            <c-image
              :src="getPhotoProgram(productPhoto)"
              :alt="item.name"
              w="100%"
            />
          </c-box>
          <c-box w="100%" :padding="['10px 24px 8px 24px', '0 20px']">
            <c-heading
              as="h2"
              mb="20px"
              color="brand.900"
              fontWeight="700"
              :fontSize="['18px', '48px']"
              :linHeight="['27px', '72px']"
            >
              {{ item.name }}
            </c-heading>
            <c-text
              mb="20px"
              :fontSize="['14px', '18px']"
              :lineHeight="['21px', '36px']"
              color="darkGrey.900"
            >
              {{ item.description }}
            </c-text>
            <c-text
              :fontSize="['14px', '18px']"
              :lineHeight="['21px', '27px']"
              fontWeight="700"
              color="brand.900"
            >
              Program ini cocok untuk
            </c-text>
            <c-list v-if="suitableFor.length > 0" mb="34px">
              <c-list-item
                v-for="(item, idx) in suitableFor"
                :key="idx"
                :fontSize="['14px', '18px']"
                :lineHeight="['21px', '36px']"
                color="darkGrey.900"
              >
                {{ item }}
              </c-list-item>
            </c-list>

            <c-text
              :fontSize="['14px', '18px']"
              :lineHeight="['21px', '27px']"
              fontWeight="700"
              color="brand.900"
            >
              Target Program
            </c-text>
            <c-list v-if="programTarget.length > 0">
              <c-list-item
                v-for="(item, idx) in programTarget"
                :key="idx"
                :fontSize="['14px', '18px']"
                :lineHeight="['21px', '36px']"
                color="darkGrey.900"
              >
                {{ item }}
              </c-list-item>
            </c-list>
          </c-box>
        </c-flex>
        <c-box :px="['24px', '56px']">
          <c-box
            v-for="(item, index) in item.productServices"
            :key="index"
            borderRadius="12px"
            border="1px solid #008C81"
            :px="['20px', '40px']"
            :py="['20px', '40px']"
            mb="30px"
          >
            <c-flex :flexDirection="['column', 'row']">
              <c-box as="div" :w="['100%', '40%']" pos="relative">
                <c-heading
                  as="h2"
                  mb="10px"
                  :color="['black.900', 'brand.900']"
                  :fontSize="['18px', '36px']"
                  fontWeight="700"
                >
                  {{ item.programsService }}
                </c-heading>
                <c-text mb="10px" fontSize="['14px', '18px']" color="gray.900">
                  Durasi {{ item.duration }} Hari
                </c-text>
                <c-text
                  mb="20px"
                  color="black.900"
                  fontSize="['18px', '24px']"
                  fontWeight="700"
                >
                  <c-text as="span" fontSize="['18px', '19px']">Rp</c-text>
                  {{ Number(item.price).toLocaleString("id") }}
                </c-text>
                <c-button
                  :w="['100%', 'auto']"
                  right-icon="arrow-forward"
                  backgroundColor="secondary.900"
                  color="#927102"
                  borderRadius="100px"
                  fontSize="16px"
                  px="15px"
                  @click.prevent="onAddToCart(item)"
                >
                  Beli Layanan
                </c-button>
              </c-box>
              <c-box
                as="div"
                :marginBottom="['20px', '0']"
                :display="['none', 'block']"
              >
                <c-text
                  fontSize="18px"
                  lineHeight="27px"
                  fontWeight="700"
                  color="darkGray.900"
                >
                  Target Layanan
                </c-text>
                <c-list v-if="item.serviceTarget">
                  <c-list-item
                    v-for="(item, index) in serviceTarget(item.serviceTarget)"
                    :key="index"
                    fontSize="18px"
                    lineHeight="36px"
                    color="darkGrey.900"
                  >
                    {{ item }}
                  </c-list-item>
                </c-list>
              </c-box>
            </c-flex>
          </c-box>

          <c-text
            fontSize="18px"
            lineHeight="36px"
            color="darkGrey.900"
            marginBottom="12px"
            :display="['none', 'block']"
          >
            {{ item.overview }}
          </c-text>
          <c-text
            fontSize="18px"
            lineHeight="27px"
            fontWeight="700"
            color="darkGray.900"
            marginBottom="15px"
            :display="['none', 'block']"
          >
            Fasilitas Layanan
          </c-text>
          <c-list
            v-if="serviceFacility.length > 0"
            :display="['none', 'block']"
          >
            <c-list-item
              v-for="(item, idx) in serviceFacility"
              :key="idx"
              fontSize="18px"
              lineHeight="36px"
              color="darkGrey.900"
            >
              {{ item }}
            </c-list-item>
          </c-list>
        </c-box>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import { mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import Header from "@/components/header/index.vue";

export default {
  name: "DetailProducts",
  props: ["item", "productPhoto", "isOpen"],
  mixins: [generalMixin],
  components: { Header },
  computed: {
    suitableFor() {
      if (!this.item.suitableFor) return "";
      return this.item.suitableFor.split("\n");
    },
    programTarget() {
      if (!this.item.programTarget) return "";
      return this.item.programTarget.split("\n");
    },
    serviceFacility() {
      if (!this.item.serviceFacility) return "";
      return this.item.serviceFacility.split("\n");
    },
  },
  methods: {
    ...mapActions({
      onCloseModal: "clients/onToggleModal",
      addToCart: "clients/addToCart",
      listCart: "cart/list",
    }),
    serviceTarget(item) {
      if (!item) return "";
      return item.split("\n");
    },
    onAddToCart(product) {
      if (!this.$store.state.auth.token) {
        this.onCloseModal(false);
        return this.$router.push({ name: "auth.login" });
      }
      let params = {
        productId: product.productId,
        productName: this.item.name,
        programService: product.programsService,
        productPrice: product.price,
        duration: product.duration,
        productPhoto: this.productPhoto,
      };

      this.addToCart(params)
        .then((r) => r.data)
        .then(() => {
          this.$router.push({ name: "client.cart" });
        });
    },
  },
};
</script>
